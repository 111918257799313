import request from '@/utils/request'

// 查询出入场流水数据列表
export function listFlow(query) {
	return request({
		url: '/park/flow/list',
		method: 'get',
		params: query
	})
}

// 查询出入场流水数据详细
export function getFlow(id) {
	return request({
		url: '/park/flow/' + id,
		method: 'get'
	})
}

// 新增出入场流水数据
export function addFlow(data) {
	return request({
		url: '/park/flow',
		method: 'post',
		data: data
	})
}

// 修改出入场流水数据
export function updateFlow(data) {
	return request({
		url: '/park/flow',
		method: 'put',
		data: data
	})
}

// 删除出入场流水数据
export function delFlow(id) {
	return request({
		url: '/park/flow/' + id,
		method: 'delete'
	})
}

// 某个通道的设备通道延迟情况
export function networkDelayReport(channelId) {
    return request({
        url: '/park/flow/networkDelayReport/' + channelId,
        method: 'get'
    })
}
